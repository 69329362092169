// Homepage loading animation
const greenBg = CSSRulePlugin.getRule(".g-header-1__grid:after");

const tl = gsap.timeline();
tl.delay(0.6);
tl.fromTo(
    ".c-stylement__bg",
    {
        minHeight: "60rem",
    },
    {
        minHeight: 0,
        scaleY: 1,
        duration: .5,
        ease: "power4.out"
    }
);
tl.fromTo(
    ".c-work__image img",
    {
        scaleX: 0,
        yPercent: -50,
        xPercent: -100,
        top: "50%",
        left: 0,
        opacity: 0
    },
    {
        scaleX: 1,
        yPercent: -50,
        xPercent: -50,
        top: "50%",
        left: "50%",
        opacity: 1,
        stagger: .1,
        duration: .2,
        ease: "power4.out"
    },
    "<+.3"
);
tl.to(
    ".g-header-1__intro",
    {
        opacity: 1,
        translateY: 0,
        duration: .5,
        ease: "power4.out"
    }
);
tl.to(
    ".g-header-1__cv-link",
    {
        opacity: 1,
        translateY: 0,
        duration: .5,
        ease: "power4.out"
    },
    "<"
);
tl.to(
    ".g-header-1__title h1",
    {
        opacity: 1,
        translateY: 0,
        duration: .5,
        ease: "power4.out"
    },
    "<+0.2"
);

// Homepage scroll animation
const positionLeft = document.querySelector(".js-animateToShape").getBoundingClientRect().left;
const positionTop = window.pageYOffset + document.querySelector(".js-animateToShape").getBoundingClientRect().top;
const finalWidth = document.querySelector(".js-animateToShape").offsetWidth + positionLeft;
const finalHeight = document.querySelector(".js-animateToShape").offsetHeight;

const scroll = gsap.timeline({
    scrollTrigger: {
        start: "5% top",
        endTrigger: ".js-animateToShape",
        end: "center center",
        scrub: true
    }
});
scroll.to(".c-stylement__bg", {
    "--main-bg": "#D8ECF2",
    width: finalWidth,
    height: finalHeight,
    left: positionLeft,
    top: positionTop
});